/* stylelint-disable font-family-no-missing-generic-family-keyword */
.companyCardH5 {
  display: block;
  margin-bottom: 6px;
  background: #fff;
  border: 1px solid #F4F5F6;
  border-radius: 12px;

  /* Normal Shadow Styles/Normal Shadow-xxl */
  box-shadow: 0 8px 14px -2px rgba(162, 183, 203, 0.15);

  .topRoot {
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 12px 20px;
  }

  .header {
    display: flex;
    flex-direction: row;
    gap: 12px;
  }

  .logo {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    border: 1px solid var(--line-border-03-eeeff-0-tertiary, #ebeef0);
    border-radius: 6px;
  }

  .name {
    /* H5/Caption/Caption-Bold */
    font-family: "SF Pro Display";
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;

    /* 137.5% */
    color: var(--text-icon-08-black, #000);
  }

  .tags {
    /* WEB/Body/Body2-regular */
    font-family: "SF Pro Display";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    color: var(--text-icon-07121212, #111212);
  }

  .desc {
    display: -webkit-box;
    overflow: hidden;

    /* WEB/Body/Body1-regular */
    font-family: "SF Pro Display";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    color: var(--text-icon-04646566, #646566);
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;

    &.defaultDesc {
      color: #acaeb1;
    }
  }

  .bottomContent {
    display: flex;
    align-items: center;
    padding: 12px 20px;
    border-top: 1px solid rgba(0, 0, 0, 0.05);
  }

  .numberFlag {
    display: flex;
    gap: 5px;
    align-items: center;

    /* WEB/Heading/H6 */
    font-family: "SF Pro Display";
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;

    /* 150% */
    color: var(--neutral-color-text-05515151, var(--color-01, #1c70ee));

    .text {
      /* WEB/Heading/Subtitle4-medium */
      font-size: 14px;
      font-weight: 500;
      color: var(--neutral-color-text-05515151, #515151);
    }
  }

  .country {
    margin-left: auto;
    font-family: "SF Pro Display";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    color: var(--text-icon-03-acaeb-1, #acaeb1);
  }
}