.translation {
  .transButton {
    position: relative;
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: #1c70ee;
    cursor: pointer;
  }

  .button {
    display: flex;
    align-items: center;
    height: 35px;
    padding: 0 8px;
    border-radius: 4px;

    svg {
      margin-right: 4px;
    }

    &:hover {
      background: #f5f7fb;
    }
  }

  .svgBox {
    width: 20px;
    height: 20px;
    margin-right: 4px;
  }

  .transButton .tipsContent {
    display: flex;
    max-width: max-content;
    padding: 8px;
    font-size: 15px;
    color: #fff;
    background: linear-gradient(90deg, #82deff -46.21%, #1C70EE 100%);
    border-radius: 6px;
  }

  .overlayOriginalWrap {
    position: absolute;

    // z-index: 6;
    // background-color: pink;

    :global {

      /* stylelint-disable-next-line selector-class-pattern */
      .ant-popover-inner {
        padding: 0;
      }

      /* stylelint-disable-next-line selector-class-pattern */
      .ant-popover-arrow::before {
        background: #347af8;
      }

      .tipsBox {
        font-size: 14px;
        color: #fff;
      }

      .svgBox {
        width: 20px;
        height: 20px;
        margin-right: 4px;
      }

      .effect {
        position: relative;
        display: flex;
        align-items: center;
        height: 34px;
        padding: 0 12px 0 8px;
        white-space: nowrap;
        background: linear-gradient(90deg, #82deff -46.21%, #1C70EE 100%);
        border-radius: 6px;
      }
    }
  }
}