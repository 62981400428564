.mobile {
  .topBg {
    height: 60px;
    background: #e9fdfe;
  }

  .noMore {
    padding: 16px 0 120px;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    color: #646566;
    text-align: center;
  }

  .header {
    position: relative;
    background: #fff;
    background-color: #fff;
    border-radius: 0 0 12px 12px;
    box-shadow: 0 4px 6px 0 rgba(162, 183, 203, 0.1);

    .info {
      position: relative;
      padding: 40px 0 20px;
    }

    .avatarBox {
      position: absolute;
      top: -34px;
      left: 50%;
      transform: translateX(-50%);
    }

    .vipAvatar {
      position: absolute;
      right: 0;
      bottom: 3px;
      width: 23px;
      height: 9px;
    }

    .avatar {
      display: block;
      width: 68px;
      height: 68px;
      margin: 0 auto;
      object-fit: cover;
      border: 2px solid #fff;
      border-radius: 50%;
      box-shadow: 0 3.4px 13.6px 0 rgba(0, 0, 0, 0.1);
    }

    .name {
      padding: 6px 20px 24px;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 28px;
      color: #000;
      text-align: center;
    }

    .container {
      display: flex;
      flex-wrap: wrap;
      gap: 24px 0;
    }

    .list {
      width: 33%;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      color: #111212;
      text-align: center;
    }

    .tips {
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      color: #3b3c3c;
    }
  }

  .filter {
    padding: 16px 20px;
    margin: 8px 0;
    background: #fff;
    border-radius: 16px;
    box-shadow: 0 4px 6px 0 rgba(162, 183, 203, 0.1);
  }

  .filterContainer {
    display: flex;
    gap: 12px;

    > div {
      flex: 1;
    }
  }

  .moreFilter {
    display: flex;
    gap: 8px;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    color: #3b3c3c;

    > span {
      display: inline-block;
      padding: 0 6px;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: 22px;
      color: #1c70ee;
      text-align: center;
      background-color: #f5f9ff;
      border-radius: 8px;
    }
  }

  .moreBtn {
    display: flex;
    justify-content: center;
    padding: 0 0 160px;
  }

  .jobContainer {
    display: flex;
    flex-direction: column;
    gap: 6px;
    padding: 0 12px 16px;
  }

  .empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 300px;
    padding-bottom: 160px;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    color: #4b4c4d;
    text-align: center;

    p {
      max-width: 70%;
    }
  }

  .footerBar {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 72px;
    background-color: #fff;
  }

  .appBtn {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: calc(100vw - 40px);
    padding: 6px 0;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    color: #fff;
    background: #1c70ee;
    border-radius: 12px;

    > p {
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 18px;
      color: rgba(255, 255, 255, 0.7);
    }
  }

  :global {
    /* stylelint-disable-next-line selector-max-class */
    .ant-select.ant-cascader .ant-select-selector {
      background-color: transparent;
      border: none !important;
    }

    /* stylelint-disable-next-line no-descending-specificity */
    .ant-select-selector {
      border: none !important;
      box-shadow: none !important;
    }
  }
}
