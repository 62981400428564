/* stylelint-disable no-descending-specificity */
/* stylelint-disable selector-class-pattern */


.banner {
  min-width: 1296px;
  max-width: 1920px;
  margin: 0 auto;

  img {
    width: 100%;
  }
}

.about {
  padding: 64px 0;
  background-color: #fff;
}

.title {
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 38px;
  color: #111827;
  text-align: center;

  span {
    position: relative;
    z-index: 10;
  }

  svg {
    position: absolute;
    right: -10px;
    bottom: 2px;
    z-index: -1;
  }
}

.aboutText {
  max-width: 1136px;
  padding-top: 24px;
  margin: 0 auto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  color: #3B3C3C;
  text-align: left;
}

.video {
  display: block;
  width: 720px;
  height: 405px;
  margin: 29px auto 0;
  object-fit: cover;
  border-radius: 12px;
}

.aboutImgs {
  width: 772px;
  margin: 24px auto 0;

  :global {
    .swiper-initialized {
      position: relative;
    }

    .swiper-button-prev::after,
    .swiper-button-next::after {
      font-size: 0 !important;
    }

    .swiper-button-prev {
      position: absolute;
      top: 50%;
      left: 22px;
      z-index: 100;
      width: 20px;
      height: 20px;
      cursor: pointer;
      background: url('./img//angle.svg') no-repeat center center;
      transform: translateY(-50%);
    }

    .swiper-button-disabled {
      cursor: default;
      opacity: 0.6;
    }

    .swiper-button-next {
      position: absolute;
      top: 50%;
      right: 22px;
      z-index: 100;
      width: 20px;
      height: 20px;
      cursor: pointer;
      background: url('./img//angle.svg') no-repeat center center;
      transform: translateY(-50%) rotate(180deg);
    }


  }
}

.thumbBox {
  margin-top: 12px;

  :global {
    .swiper-slide {
      height: 104px;
      border: 2px solid #fff;

      img {
        height: 100px !important;
        object-fit: cover;
      }
    }

    .swiper-slide-thumb-active {
      border: 2px solid #3CEAF6;
      border-radius: 8px;

    }
  }
}

.JobsContainer {
  background: #F8F9FA;
}

.jobs {
  width: 1136px;
  padding: 64px 0;
  margin: 0 auto;

  :global {
    a:hover {
      color: unset !important;
    }
  }

  .box {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    padding-top: 30px;
  }

  .item {
    width: calc(50% - 6px);
  }
}

.company {
  padding: 64px 0;
  background-color: #ffff;

  .box {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    width: 1136px;
    padding-top: 30px;
    margin: 0 auto;
  }

  .item {
    width: calc(50% - 6px);
  }
}

.bottomBox {
  img {
    display: block;
    max-width: 1136px;
    margin: 0 auto;
  }
}

.footer {
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: center;
  padding: 32px 0;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  color: rgba(255, 255, 255, 0.70);
  background: #000;
}

.floatBtn {
  position: fixed;
  right: 20px;
  bottom: 160px;
  z-index: 100;
  display: flex;
  flex-direction: column;
  gap: 6px;
  align-items: center;
  padding: 8px;
  background: rgba(0, 0, 0, 0.31);
  backdrop-filter: blur(19px);
  border-radius: 50px;

  .item {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    cursor: pointer;
    border-radius: 30px;

    &:hover {
      background: rgba(1, 1, 1, 0.10);
    }
  }

  .line {
    width: 16px;
    height: 1px;
    background: rgba(246, 246, 246, 0.30);

  }
}

.wrapClass {
  :global {
    .ant-modal .ant-modal-content {
      padding: 32px 20px;
      border-radius: 16px;
    }
  }

  .fTitle {
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    color: #000;
    text-align: center;
  }

  .fContent {
    padding: 24px 0;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    color: #3B3C3C;

    ol,
    ul {
      padding-left: 20px;
    }
  }

  .footerBox {
    display: flex;
    gap: 8px;

    >div {
      flex: 1;
    }
  }
}

.popoverClass {
  :global {
    .ant-popover-content {
      .ant-popover-inner {
        padding: 12px;
        border-radius: 12px;
      }

      .ant-popover-title {
        margin-bottom: 12px;
        font-size: 20px;
        color: #000;
      }
    }
  }
}

.shareMenu {
  display: flex;
  flex-direction: column;
}

.shareItem,
.ModalShareItemLink {
  display: flex;
  gap: 8px;
  align-items: center;
  width: 100%;
  min-height: 44px;
  padding: 0 0 0 8px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  color: #111212;
  cursor: pointer;
  border-radius: 8px;

  &:hover {
    color: unset;
    background: #F8F9FA;

  }
}

.newModal {
  position: fixed;
  inset: 0;
  z-index: 100;

  .mask {
    position: absolute;
    inset: 0;
    z-index: 1;
    background: rgba(0, 0, 0, 0.31);
  }

  .body {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 10;
    min-height: 150px;
    padding: 40px 20px;
    background-color: #fff;
    border-radius: 12px 12px 0 0;

    .contactBox {
      padding-top: 24px;
    }

    .shareMenu {
      flex-direction: unset;
      flex-wrap: wrap;
      gap: 24px 0;

      .shareItem {
        width: 25%;
      }
    }

    .shareItem {
      flex-direction: column;

      button {
        flex-direction: column;
      }

      svg {
        flex-shrink: 0;
        width: 40px !important;
        height: 40px !important;
      }
    }



    .close {
      position: absolute;
      top: 16px;
      right: 18px;
    }
  }

  .dTitle {
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    color: #000;
    text-align: center;
  }
}

.page {
  padding-top: 32px;
}

.loadingBox {
  max-height: unset !important;
}