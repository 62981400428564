.benefits {
  // padding-bottom: 24px;
  margin-top: 24px;

  .title {
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    color: #121212;
  }

  ul {
    list-style: none;
  }

  li {
    display: flex;
    align-items: flex-start;
    padding: 24px 0 0;
    list-style: none;

    .category {
      margin-bottom: 7px;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 22px;
    }

    .main {
      flex: 1;
      margin-left: 12px;
    }

    .tag {
      font-size: 14px;
      line-height: 1.6;
      color: #7d7d7d;
      word-break: break-word;
    }
  }

  .hide {
    display: none;
  }

  .showmore {
    display: inline-flex;
    align-items: center;
    padding-top: 32px;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    color: #121212;
    cursor: pointer;

    svg {
      margin-left: 4px;
    }

    .arrowUp {
      transform: rotate(180deg);
    }
  }
}
