/* stylelint-disable */

.jobCard {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: flex-start;
  width: 100%;
  background: #fff;
  border: 1px solid #f4f5f6;
  border-radius: 12px;

  .jobCardLink {
    width: 100%;
    height: 100%;
    padding: 16px 12px;
    color: #121212;
    text-decoration: none;
  }

  .jobCardLine {
    display: flex;
    width: 100%;
    margin: 12px 0;
    border-top: 1px solid #ebeef0;
  }

  .jobCardTitle {
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
    color: #000;
  }

  .jobCardUrgent {
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px;
    color: #000;
    vertical-align: text-bottom;
    background: #3ceaf6;
    border-radius: 6px;
    display: inline-flex;
    height: 22px;
    padding: 0px 6px 0px 4px;
    align-items: center;
    gap: 2px;
    justify-content: center;

    svg {
      vertical-align: text-bottom;
    }
  }

  .jobCardSalary {
    display: flex;
    gap: 8px;
    align-items: center;
    margin-top: 1px;
  }

  .jobCardSalaryValue {
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    color: #1c70ee;
    text-align: right;
  }

  .jobCardSalaryType {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    color: #111212;
    text-align: right;
  }

  .jobCardCompany {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    color: #3b3c3c;
  }

  .jobCardLocation {
    display: flex;
    flex-wrap: wrap;
    gap: 6px;
    margin-top: 8px;
    overflow: hidden;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    color: #3b3c3c;
  }

  .jobCardLocationItem {
    display: inline-block;
    height: 28px;
    padding: 0 6px;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    color: #3b3c3c;
    background: #f8f9fa;
    border-radius: 8px;
  }

  .jobCardLocationRemote {
    color: #3a90ff;
  }

  .jobCardFooter {
    display: flex;
    flex-direction: row;
    align-items: center;

    // margin-top: 30px;
  }

  .jobCardFooterAvatar {
    position: relative;
    flex-shrink: 0;
    width: 24px;
    height: 24px;
    margin-right: 4px;
  }

  .jobCardFooterAvatarImage {
    position: relative;
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background: #fff;
    border: 1px solid #fff;
    border-radius: 50%;
    box-shadow: 0 4px 6px 0 rgba(162, 183, 203, 0.1);
  }

  .jobCardFooterAvatarImage.offline {
    border-color: #e4e4e4;
  }

  .jobCardFooterAvatarOnline {
    position: absolute;
    right: 2px;
    bottom: 0;
    display: block;
    width: 6px;
    height: 6px;
    content: "";
    background: #34c759;
    border: 1px solid #fff;
    border-radius: 50%;
  }

  .jobCardFooterInfo {
    display: flex;
    flex: 1;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    color: #4b4c4d;
  }

  .jobCardFooterInfoText {
    flex: 1;
    width: 0;
  }

  .jobCardFooterInfoName {
    display: inline;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    color: #000;

    span {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 60vw;
      display: block;
    }
  }

  .jobCardFooterInfoDot {
    line-height: 24px;
    color: #acaeb1;
    padding: 0 5px;
  }

  .jobCardFooterInfoJobTitle {
    display: inline;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    color: #646566;
  }
}
